/* eslint-disable default-param-last */
import { options as o } from '../actions/types';

const oldState = {
};

export default function options(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case o.SET_OPTION_CATEGORY: {
        return {
            ...state,
            currentCategory: payload,
        };
    }
    case o.OPTION_RECEIVED:
        return {
            ...state,
            currentOption: payload.data,
        };
    case o.OPTIONS_RECEIVED:
        return {
            ...state,
            options: payload.data,
        };
    default:
        return state;
    }
}
