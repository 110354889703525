/* eslint-disable default-param-last */
import { error as e } from '../actions/types';

const initialError = {
    newBCError: {},
};

export default function error(state = initialError, action) {
    const { type, payload } = action;
    switch (type) {
    case e.SET_ERROR:
        return payload;
    case e.SET_NEW_BC_ERROR:
        return {
            ...state,
            newBCError: payload,
        };
    default:
        return state;
    }
}
