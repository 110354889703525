import React from 'react';
import { useTheme } from '@mui/material/styles';
// project import
import Navigation from './Navigation';
import SimpleBar from '../../../../components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

function DrawerContent() {
    const theme = useTheme();

    return (
        <SimpleBar
            sx={{
                '& .simplebar-content': {
                    background: theme.palette.menu.background,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Navigation />
        </SimpleBar>
    );
}

export default DrawerContent;
