import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar, IconButton, Toolbar, useMediaQuery,
} from '@mui/material';

// project import
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

function Header({ open, handleDrawerToggle }) {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('xl'));

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <HeaderContent />
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            // boxShadow: theme.customShadows.z1
        },
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
}

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
};

export default Header;
