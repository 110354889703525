/* eslint-disable default-param-last */
import { offeritemset as o } from '../actions/types';

const oldState = {
};

export default function offeritemsets(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case o.OFFERITEMSETS_RECEIVED:
        return {
            ...state,
            offeritemsets: payload.data,
        };
    case o.OFFERITEMSET_RECEIVED:
        return {
            ...state,
            currentItemSet: payload.data,
        };
    case o.OFFERITEMSET_UPDATED:
        return {
            ...state,
            currentItemSet: payload,
        };
    case o.OFFERITEMSET_SUCCESS:
        return {
            ...state,
            setCreated: true,
        };
    case o.OFFERITEMSET_FAIL:
        return {
            ...state,
            setCreated: false,
        };
    case o.SET_CURRENT_SET_ITEMS:
        return {
            ...state,
            currentSetItems: payload,
        };
    case o.SET_ITEMS_CATEGORY:
        return {
            ...state,
            itemsCategory: payload,
        };
    case o.SET_CATEGORY_OF_ITEMS:
        return {
            ...state,
            categoryOfItems: payload,
        };
    case o.SET_CURRENT_SET_ID:
        return {
            ...state,
            currentSetId: payload,
        };
    case o.SET_NEW_SET_ITEM:
        return {
            ...state,
            newSetItem: payload,
        };
    case o.SET_NEW_SET:
        return {
            ...state,
            newSet: payload,
        };
    case o.SET_CURRENT_SET:
        return {
            ...state,
            currentItemSet: payload,
        };
    default:
        return state;
    }
}
