/* eslint-disable default-param-last */
import { offeritems as o } from '../actions/types';

const oldState = {
};

export default function offeritems(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case o.OFFERITEMCATEGORIES_RECEIVED:
        return {
            ...state,
            categories: payload.data,
        };
    case o.OFFERITEMCATEGORY_RECEIVED:
        return {
            ...state,
            currentCategory: payload.data,
        };
    case o.OFFERITEMS_RECEIVED:
        return {
            ...state,
            offeritems: payload.data,
        };
    case o.OFFERITEM_RECEIVED:
        return {
            ...state,
            currentOfferItem: payload.data,
        };
    case o.OFFERITEMCATEGORY_SUCCESS:
        return {
            ...state,
            categoryCreated: true,
        };
    case o.OFFERITEMCATEGORY_FAIL:
        return {
            ...state,
            categoryCreated: false,
        };
    case o.OFFERITEM_SUCCESS:
        return {
            ...state,
            itemCreated: true,
        };
    case o.OFFERITEM_FAIL:
        return {
            ...state,
            itemCreated: false,
        };
    case o.SET_NEW_OFFERITEM:
        return {
            ...state,
            newItem: payload,
        };
    case o.SET_NEW_OFFERITEMCATEGORY:
        return {
            ...state,
            newCategory: payload,
        };
    case o.SET_OFFERITEM:
        return {
            ...state,
            currentOfferItem: payload,
        };
    case o.SET_OFFERITEMCATEGORY:
        return {
            ...state,
            currentCategory: payload,
        };
    default:
        return state;
    }
}
