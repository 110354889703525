import React from 'react';
import { useSelector } from 'react-redux';
// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';

// project import
import pages from '../../../../../menu-items/pages';
import admin from '../../../../../menu-items/admin';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

function Navigation() {
    const currentRole = useSelector((state) => state?.user?.data?.role);
    let menuItemsArr = [];
    if (currentRole === 'admin' || currentRole === 'manager') {
        menuItemsArr = [pages, admin];
    } else {
        menuItemsArr = [pages];
    }

    const navGroups = menuItemsArr.map((item) => {
        switch (item.type) {
        case 'group':
            return (
                <NavGroup
                    key={item.id}
                    item={item}
                />
            );
        default:
            return (
                <Typography key={item.id} variant="h6" color="error" align="center">
                    Fix - Navigation Group
                </Typography>
            );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
}

export default Navigation;
