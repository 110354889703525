import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Chip,
    Grid,
    IconButton,
} from '@mui/material';

import {
    PlusOutlined,
} from '@ant-design/icons';

// project import
import Highlighter from './third-party/Highlighter';

// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            counter,
            counterColor,
            modalSetter,
            height,
            backgroundPosition,
            backgroundColor = '#FFFFFF',
            background,
            backgroundSize = 'contain',
            borderTopColor,
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            codeHighlight,
            borderTop = false,
            ...others
        },
        ref,
    ) => {
        const theme = useTheme();
        const { t } = useTranslation();
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;
        borderTopColor = borderTopColor !== undefined ? borderTopColor : theme.palette.primaryblack.main;
        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    ...sx,
                    height,
                    background: background ? `url(${background}) no-repeat` : 'none',
                    backgroundColor,
                    backgroundPosition,
                    backgroundSize,
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                    borderTop: borderTop ? `5px solid ${borderTopColor}` : '',
                    boxShadow: boxShadow && (!border
                    || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit',
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                    },
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && counter === undefined && (
                    <CardHeader
                        sx={headerSX}
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        title={title}
                        action={secondary}
                    />
                )}
                {!darkTitle && title && counter >= 0 && (
                    <CardHeader
                        sx={headerSX}
                        title={(
                            <Grid container columnSpacing={1} alignItems="center">
                                <Grid item xs={modalSetter === undefined ? 12 : 10}>
                                    <Grid container>
                                        <Grid item mr={2}>
                                            <Typography
                                                sx={{ lineHeight: 2.1 }}
                                                variant="subtitle1"
                                            >
                                                {t(title)}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size="small"
                                                color={counterColor}
                                                label={counter}
                                                variant="light"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {modalSetter !== undefined && (
                                    <Grid item xs={2} display="flex" justifyContent="flex-end">
                                        <IconButton
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                color: theme.palette.primary.contrastText,
                                                bgcolor: theme.palette.primary.dark,
                                                '&:hover': {
                                                    color: theme.palette.primary.contrastText,
                                                    bgcolor: theme.palette.primary.darker,
                                                },
                                            }}
                                            onClick={() => {
                                                modalSetter(true);
                                            }}
                                        >
                                            <PlusOutlined />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        action={secondary}
                    />
                )}
                {darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={(<Typography variant="h3">{title}</Typography>)}
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {title && divider && <Divider />}

                {/* card content */}
                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}

                {/* card footer - clipboard & highlighter  */}
                {codeHighlight && (
                    <>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <Highlighter codeHighlight={codeHighlight} main>
                            {children}
                        </Highlighter>
                    </>
                )}
            </Card>
        );
    },
);

MainCard.propTypes = {
    counter: PropTypes.number,
    height: PropTypes.string,
    modalSetter: PropTypes.func,
    backgroundPosition: PropTypes.string,
    backgroundColor: PropTypes.string,
    background: PropTypes.any,
    backgroundSize: PropTypes.any,
    borderTopColor: PropTypes.string,
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    divider: PropTypes.bool,
    elevation: PropTypes.number,
    secondary: PropTypes.node,
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.string,
    codeHighlight: PropTypes.bool,
    content: PropTypes.bool,
    borderTop: PropTypes.bool,
    children: PropTypes.node,
    counterColor: PropTypes.string,
};

export default MainCard;
