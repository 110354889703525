import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { breadcrumbs as b } from '../actions/types';
import { activeItem } from '../reducers/menu';

function BreadcrumbsProvider({ breadcrumbs, activateItem, children }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(activeItem({ openItem: [activateItem || 'dashboard'] }));
    }, [activateItem]);

    useEffect(() => {
        dispatch({ type: b.SET_BREADCRUMBS, payload: breadcrumbs });
    }, [breadcrumbs]);

    return children;
}

export default BreadcrumbsProvider;
