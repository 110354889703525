import { combineReducers } from 'redux';
import message from './message';
import menu from './menu';
import user from './user';
import tokens from './tokens';
import auth from './auth';
import client from './client';
import businesscase from './businesscase';
import businessoffer from './businessoffer';
import loader from './loader';
import reload from './reload';
import enumerations from './enumerations';
import offeritems from './offeritems';
import offeritemsets from './offeritemsets';
import tags from './tags';
import documents from './documents';
import statistics from './statistics';
import sse from './sse';
import options from './options';
import breadcrumbs from './breadcrumbs';
import note from './note';
import error from './error';

export default combineReducers({
    reload,
    loader,
    auth,
    message,
    menu,
    user,
    tokens,
    client,
    businesscase,
    enumerations,
    offeritems,
    businessoffer,
    offeritemsets,
    tags,
    documents,
    statistics,
    sse,
    options,
    breadcrumbs,
    note,
    error,
});
