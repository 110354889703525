/* eslint-disable default-param-last */
import { tags as t } from '../actions/types';

const oldState = {
};

export default function tags(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case t.TAGS_SUCCESS:
        return { ...state, tags: payload.data };
    case t.TAG_SUCCESS:
        return { ...state, currentTag: payload.data };
    case t.SET_NEW_TAG:
        return { ...state, newTag: payload };
    case t.SET_CURRENT_TAG:
        return { ...state, currentTag: payload };
    default:
        return state;
    }
}
