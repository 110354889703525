import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from '../../../../components/Logo';

// ==============================|| DRAWER HEADER ||============================== //

function DrawerHeader({ open }) {
    const theme = useTheme();

    return (
        // only available in paid version
        <DrawerHeaderStyled
            theme={theme}
            open={open}
            sx={{ backgroundColor: theme.palette.menu.background }}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                <Logo />
            </Stack>
        </DrawerHeaderStyled>
    );
}

DrawerHeader.propTypes = {
    open: PropTypes.bool,
};

export default DrawerHeader;
