import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box, Toolbar, useMediaQuery,
} from '@mui/material';

// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from '../../menu-items';
import Breadcrumbs from '../../components/@extended/Breadcrumbs';

// types
import { openDrawer } from '../../reducers/menu';

// ==============================|| MAIN LAYOUT ||============================== //

function MainLayout() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    // drawer toggler
    const { drawerOpen } = useSelector((state) => state.menu);
    const [open, setOpen] = useState(drawerOpen);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Breadcrumbs navigation={navigation} card="false" divider={false} />
                <Outlet />
            </Box>
        </Box>
    );
}

export default MainLayout;
