/* eslint-disable default-param-last */
import { businessoffer as b } from '../actions/types';

const oldState = {
};

export default function businessoffer(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case b.BUSINESSOFFER_FAIL:
        return {
            ...state,
        };
    case b.BUSINESSOFFER_SUCCESS:
        return {
            ...state,
        };
    case b.BUSINESSOFFERS_RECEIVED:
        return {
            ...state,
            businessoffers: payload.data,
        };
    case b.BUSINESSOFFER_RECEIVED:
        return {
            ...state,
            currentBo: payload.data,
        };
    case b.BUSINESSOFFERSET_RECEIVED:
        return {
            ...state,
            boSet: payload.data,
        };
    case b.BUSINESSOFFERSETS_RECEIVED:
        return {
            ...state,
            offersets: payload.data,
        };
    case b.BUSINESSOFFERSTATES_RECEIVED:
        return {
            ...state,
            offerstates: payload.data,
        };
    default:
        return state;
    }
}
