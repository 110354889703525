// project import
import pages from './pages';
import admin from './admin';
import UserService from '../services/user.service';

// ==============================|| MENU ITEMS ||============================== //

const currentUser = UserService.getCurrentUser();
let menuItemsArr = [];

if (currentUser?.role === 'admin') {
    menuItemsArr = {
        items: [pages, admin],
    };
} else {
    menuItemsArr = {
        items: [pages],
    };
}

const menuItems = menuItemsArr;

export default menuItems;
