import React from 'react';
import { SnackbarProvider } from 'notistack';
import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function App() {
    return (
        <ThemeCustomization>
            <ScrollTop>
                <SnackbarProvider maxSnack={10} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Routes />
                </SnackbarProvider>
            </ScrollTop>
        </ThemeCustomization>
    );
}

export default App;
