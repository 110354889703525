// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
    const commonCell = {
        '&:last-of-type': {
            textAlign: 'right',
        },
    };

    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    padding: 12,
                    borderColor: theme.palette.divider,
                    ...commonCell,
                },
                sizeSmall: {
                    padding: 8,
                },
                head: {
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    ...commonCell,
                },
                footer: {
                    fontSize: '0.75rem',
                    ...commonCell,
                },
            },
        },
    };
}
