/* eslint-disable default-param-last */
import { loading as l } from '../actions/types';

let oldState = {};

export default function loading(state = oldState, action) {
    const { type, enumType } = action;
    const loaderName = `getEnums-${enumType}`;

    switch (type) {
    case l.CLEAR_LOADERS:
        oldState = {};
        return {};
    case l.LOADING_GETCLIENTSTATES:
        oldState = { ...oldState, getClientStates: false };
        return {
            ...state,
            getClientStates: false,
        };
    case l.LOADED_GETCLIENTSTATES:
        oldState = { ...oldState, getClientStates: true };
        return {
            ...state,
            getClientStates: true,
        };
    case l.LOADING_GETCLIENT:
        oldState = { ...oldState, getClient: false };
        return {
            ...state,
            getClient: false,
        };
    case l.LOADED_GETCLIENT:
        oldState = { ...oldState, getClient: true };
        return {
            ...state,
            getClient: true,
        };
    case l.LOADING_GETCLIENTS:
        oldState = { ...oldState, getClients: false };
        return {
            ...state,
            getClients: false,
        };
    case l.LOADED_GETCLIENTS:
        oldState = { ...oldState, getClients: true };
        return {
            ...state,
            getClients: true,
        };
    case l.LOADING_GETUSERS:
        oldState = { ...oldState, getUsers: false };
        return {
            ...state,
            getUsers: false,
        };
    case l.LOADED_GETUSERS:
        oldState = { ...oldState, getUsers: true };
        return {
            ...state,
            getUsers: true,
        };
    case l.LOADING_GETUSER:
        oldState = { ...oldState, getUser: false };
        return {
            ...state,
            getUser: false,
        };
    case l.LOADED_GETUSER:
        oldState = { ...oldState, getUser: true };
        return {
            ...state,
            getUser: true,
        };
    case l.LOADING_GETCLIENTLOG:
        oldState = { ...oldState, getClientLog: false };
        return {
            ...state,
            getClientLog: false,
        };
    case l.LOADED_GETCLIENTLOG:
        oldState = { ...oldState, getClientLog: true };
        return {
            ...state,
            getClientLog: true,
        };
    case l.LOADING_GETBUSINESSCASES:
        oldState = { ...oldState, getBusinessCases: false };
        return {
            ...state,
            getBusinessCases: false,
        };
    case l.LOADED_GETBUSINESSCASES:
        oldState = { ...oldState, getBusinessCases: true };
        return {
            ...state,
            getBusinessCases: true,
        };
    case l.LOADING_GETBUSINESSCASE:
        oldState = { ...oldState, getOneBs: false };
        return {
            ...state,
            getOneBs: false,
        };
    case l.LOADED_GETBUSINESSCASE:
        oldState = { ...oldState, getOneBs: true };
        return {
            ...state,
            getOneBs: true,
        };
    case l.LOADING_BUSINESSOFFERSET:
        oldState = { ...oldState, businessOfferSet: false };
        return {
            ...state,
            businessOfferSet: false,
        };
    case l.LOADED_BUSINESSOFFERSET:
        oldState = { ...oldState, businessOfferSet: true };
        return {
            ...state,
            businessOfferSet: true,
        };
    case l.LOADING_GETBUSINESSSTATES:
        oldState = { ...oldState, getBusinessStates: true };
        return {
            ...state,
            getBusinessStates: false,
        };
    case l.LOADED_GETBUSINESSSTATES:
        oldState = { ...oldState, getBusinessStates: false };
        return {
            ...state,
            getBusinessStates: true,
        };
    case l.LOADING_ENUMERATIONS:
        oldState = { ...oldState, getEnums: false };
        return {
            ...state,
            getEnums: false,
        };
    case l.LOADED_ENUMERATIONS:
        oldState = { ...oldState, getEnums: true };
        return {
            ...state,
            getEnums: true,
        };
    case l.LOADING_ENUMERATIONTYPE:
        oldState = { ...oldState, [loaderName]: false };
        return {
            ...state,
            [loaderName]: false,
        };
    case l.LOADED_ENUMERATIONTYPE:
        oldState = { ...oldState, [loaderName]: true };
        return {
            ...state,
            [loaderName]: true,
        };
    case l.LOADING_OFFERITEMCATEGORIES:
        oldState = { ...oldState, getOfferItemCategories: false };
        return {
            ...state,
            getOfferItemCategories: false,
        };
    case l.LOADED_OFFERITEMCATEGORIES:
        oldState = { ...oldState, getOfferItemCategories: true };
        return {
            ...state,
            getOfferItemCategories: true,
        };
    case l.LOADING_OFFERITEMCATEGORY:
        oldState = { ...oldState, getOfferItemCategory: false };
        return {
            ...state,
            getOfferItemCategory: false,
        };
    case l.LOADED_OFFERITEMCATEGORY:
        oldState = { ...oldState, getOfferItemCategory: true };
        return {
            ...state,
            getOfferItemCategory: true,
        };
    case l.LOADING_OFFERITEMS:
        oldState = { ...oldState, getOfferItems: false };
        return {
            ...state,
            getOfferItems: false,
        };
    case l.LOADED_OFFERITEMS:
        oldState = { ...oldState, getOfferItems: true };
        return {
            ...state,
            getOfferItems: true,
        };
    case l.LOADING_OFFERITEM:
        oldState = { ...oldState, getOfferItem: false };
        return {
            ...state,
            getOfferItem: false,
        };
    case l.LOADED_OFFERITEM:
        oldState = { ...oldState, getOfferItem: true };
        return {
            ...state,
            getOfferItem: true,
        };
    case l.LOADING_OFFERITEMSETS:
        oldState = { ...oldState, getItemSets: false };
        return {
            ...state,
            getItemSets: false,
        };
    case l.LOADED_OFFERITEMSETS:
        oldState = { ...oldState, getItemSets: true };
        return {
            ...state,
            getItemSets: true,
        };
    case l.LOADING_OFFERITEMSET:
        oldState = { ...oldState, getItemSet: false };
        return {
            ...state,
            getItemSet: false,
        };
    case l.LOADED_OFFERITEMSET:
        oldState = { ...oldState, getItemSet: true };
        return {
            ...state,
            getItemSet: true,
        };
    case l.LOADING_GETOFFER:
        oldState = { ...oldState, getOffer: false };
        return {
            ...state,
            getOffer: false,
        };
    case l.LOADED_GETOFFER:
        oldState = { ...oldState, getOffer: true };
        return {
            ...state,
            getOffer: true,
        };
    case l.LOADING_GETTAGS:
        oldState = { ...oldState, getTags: false };
        return {
            ...state,
            getTags: false,
        };
    case l.LOADED_GETTAGS:
        oldState = { ...oldState, getTags: true };
        return {
            ...state,
            getTags: true,
        };
    case l.LOADING_GETTAG:
        oldState = { ...oldState, getTag: false };
        return {
            ...state,
            getTag: false,
        };
    case l.LOADED_GETTAG:
        oldState = { ...oldState, getTag: true };
        return {
            ...state,
            getTag: true,
        };
    case l.LOADING_DOCUMENTS:
        oldState = { ...oldState, getDocuments: false };
        return {
            ...state,
            getDocuments: false,
        };
    case l.LOADED_DOCUMENTS:
        oldState = { ...oldState, getDocuments: true };
        return {
            ...state,
            getDocuments: true,
        };
    case l.LOADING_DOCUMENT:
        oldState = { ...oldState, getDocument: false };
        return {
            ...state,
            getDocument: false,
        };
    case l.LOADED_DOCUMENT:
        oldState = { ...oldState, getDocument: true };
        return {
            ...state,
            getDocument: true,
        };
    case l.LOADING_AVAILABLE_DOCUMENT:
        oldState = { ...oldState, getAvailableDocuments: false };
        return {
            ...state,
            getAvailableDocuments: false,
        };
    case l.LOADED_AVAILABLE_DOCUMENT:
        oldState = { ...oldState, getAvailableDocuments: true };
        return {
            ...state,
            getAvailableDocuments: true,
        };
    case l.LOADING_CLIENTSTATS:
        oldState = { ...oldState, getClientStatistics: false };
        return {
            ...state,
            getClientStatistics: false,
        };
    case l.LOADED_CLIENTSTATS:
        oldState = { ...oldState, getClientStatistics: true };
        return {
            ...state,
            getClientStatistics: true,
        };
    case l.LOADING_LEADSTATS:
        oldState = { ...oldState, getLeadStatistics: false };
        return {
            ...state,
            getLeadStatistics: false,
        };
    case l.LOADED_LEADSTATS:
        oldState = { ...oldState, getLeadStatistics: true };
        return {
            ...state,
            getLeadStatistics: true,
        };
    case l.LOADING_OFFERSTATS:
        oldState = { ...oldState, getOfferStatistics: false };
        return {
            ...state,
            getOfferStatistics: false,
        };
    case l.LOADED_OFFERSTATS:
        oldState = { ...oldState, getOfferStatistics: true };
        return {
            ...state,
            getOfferStatistics: true,
        };
    case l.LOADING_BUSINESSOFFERSTATES:
        oldState = { ...oldState, getOfferStates: false };
        return {
            ...state,
            getOfferStates: false,
        };
    case l.LOADED_BUSINESSOFFERSTATES:
        oldState = { ...oldState, getOfferStates: true };
        return {
            ...state,
            getOfferStates: true,
        };
    case l.LOADING_AVAILABLEDOCUMENT:
        oldState = { ...oldState, getAvailableDocuments: false };
        return {
            ...state,
            getAvailableDocuments: false,
        };
    case l.LOADED_AVAILABLEDOCUMENT:
        oldState = { ...oldState, getAvailableDocuments: true };
        return {
            ...state,
            getAvailableDocuments: true,
        };
    case l.LOADING_OPTIONS:
        oldState = { ...oldState, getOptions: false };
        return {
            ...state,
            getOptions: false,
        };
    case l.LOADED_OPTIONS:
        oldState = { ...oldState, getOptions: true };
        return {
            ...state,
            getOptions: true,
        };
    case l.LOADING_OPTION:
        oldState = { ...oldState, getOption: false };
        return {
            ...state,
            getOption: false,
        };
    case l.LOADED_OPTION:
        oldState = { ...oldState, getOption: true };
        return {
            ...state,
            getOption: true,
        };
    default:
        return state;
    }
}
