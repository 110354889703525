import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

function NavItem({ item, level }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const menu = useSelector((state) => state.menu);
    const { drawerOpen, openItem } = menu;

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: forwardRef((props, ref) => (
            <Link
                ref={ref}
                {...props}
                to={item.url}
                target={itemTarget}
            />
        )),
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    const textColor = theme.palette.menu.text.default;
    const iconSelectedColor = theme.palette.menu.text.active;

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: theme.palette.menu.active,
                        color: theme.palette.menu.text.active,
                    },
                    '&.Mui-selected': {
                        bgcolor: theme.palette.menu.active,
                        borderRight: `3px solid ${theme.palette.menu.text.active}`,
                        color: theme.palette.menu.text.active,
                        '&:hover': {
                            color: theme.palette.menu.text.active,
                            bgcolor: theme.palette.menu.active,
                        },
                    },
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        bgcolor: 'transparent',
                    },
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter',
                            },
                        }),
                        ...(!drawerOpen
                            && isSelected && {
                            bgcolor: 'primary.lighter',
                            '&:hover': {
                                bgcolor: 'primary.lighter',
                            },
                        }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={(
                        <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>

                            {t(item.title)}
                        </Typography>
                    )}
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
}

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number,
};

export default NavItem;
