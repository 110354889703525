// ==============================|| OVERRIDES - LINK ||============================== //

export default function Link(theme) {
    return {
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
            styleOverrides: {
                color: theme.palette.grey[900],
            },
        },
    };
}
