import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    AppBar, Box, ClickAwayListener, IconButton, Paper, Popper, Toolbar, Grid, CardContent, Stack, Typography,
} from '@mui/material';

// project import
import { MoreOutlined, LogoutOutlined } from '@ant-design/icons';
import Profile from './Profile';
import ProfileTab from './Profile/ProfileTab';
import Transitions from '../../../../components/@extended/Transitions';

import { logout } from '../../../../actions/auth';

// assets

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

function MobileSection() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const breakpoint = theme.breakpoints.down('md');

    const user = useSelector((state) => state.user.data);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        logout(dispatch);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <IconButton
                    component="span"
                    disableRipple
                    sx={{
                        bgcolor: open ? 'grey.300' : 'grey.100',
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <MoreOutlined />
                </IconButton>
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: 290,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 250,
                                },
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <AppBar color="inherit" sx={{ borderRadius: '4px' }}>
                                    <Toolbar style={{ padding: 0 }}>
                                        {!breakpoint && (<Profile />)}
                                        {breakpoint && (
                                            <CardContent sx={{ p: 0 }}>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={6} pl={3} py={2}>
                                                        <Stack direction="row" spacing={1.25} alignItems="center">
                                                            <Stack>
                                                                <Typography variant="h6">
                                                                    {user.firstName}
                                                                    {' '}
                                                                    {user.lastName}

                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" />
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={6} px={2} display="flex" justifyContent="flex-end">
                                                        <IconButton
                                                            size="large"
                                                            color="secondary"
                                                            onClick={handleLogout}
                                                        >
                                                            <LogoutOutlined />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ProfileTab handleLogout={handleLogout} />
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        )}
                                    </Toolbar>
                                </AppBar>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default MobileSection;
