/* eslint-disable default-param-last */
import { sse as s } from '../actions/types';

const initialState = {};
export default function sse(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
    case s.LAST_EVENT:
        return {
            ...state,
            lastEventId: payload,
        };
    default:
        return state;
    }
}
