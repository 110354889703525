/* eslint-disable default-param-last */
import { auth as a, user as u } from '../actions/types';
import UserService from '../services/user.service';

const oldUserData = UserService.getCurrentUser();
const oldState = {
    updated: null,
    data: oldUserData,
};

export default function user(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case a.LOGIN_SUCCESS:
        UserService.setUser(payload.data.user);
        return {
            ...state,
            updated: null,
            data: payload.data.user,
            isLoggedIn: true,
        };
    case a.LOGIN_FAIL:
        return {
            ...state,
            updated: null,
            data: null,
            isLoggedIn: false,
        };
    case a.LOGOUT:
        return {
            ...state,
            data: null,
            isLoggedIn: false,
        };
    case u.USER_UPDATE_SUCCESS:
        return {
            ...state,
            updated: true,
            data: payload.data,
            isLoggedIn: true,
        };
    case u.USER_UPDATE_FAIL:
        return {
            ...state,
            updated: false,
            isLoggedIn: true,
        };
    case a.PSWD_CORRECT:
        return {
            ...state,
            pswdMatch: true,
        };
    case a.PSWD_INCORRECT:
        return {
            ...state,
            pswdMatch: false,
        };
    case u.USERS_SUCCESS:
        return {
            ...state,
            users: payload.data,
        };
    case u.USERS_SUCCESS_ALTERNATIVE:
        return {
            ...state,
            usersAlternative: payload.data,
        };
    case u.USER_SUCCESS:
        return {
            ...state,
            currentUser: payload.data,
        };
    case u.USERS_FAILED:
        return {
            ...state,
            users: null,
        };
    case u.SET_NEW_USER:
        return {
            ...state,
            newUser: payload,
        };
    case u.SET_CURRENT_USER:
        return {
            ...state,
            currentUser: payload,
        };
    default:
        return state;
    }
}
