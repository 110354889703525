import React from 'react';
import PropTypes from 'prop-types';
import LogoSVG from '../../assets/images/workey_logo_text.svg';
import LogoSVGdark from '../../assets/images/workey_logo_dark_text.svg';

function Logo({ logoDark }) {
    return <img src={logoDark ? LogoSVGdark : LogoSVG} alt="WorkEY" width="200" />;
}

Logo.propTypes = {
    logoDark: PropTypes.bool,
};

export default Logo;
