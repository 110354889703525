// assets
import {
    ChromeOutlined, QuestionOutlined, PaperClipOutlined,
    SettingOutlined, ShoppingOutlined, TeamOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';
// icons
const icons = {
    SettingOutlined,
    ShoppingOutlined,
    TeamOutlined,
    QuestionCircleOutlined,
    ChromeOutlined,
    QuestionOutlined,
    PaperClipOutlined,
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //
const admin = {
    id: 'support',
    title: 'menu.admin',
    type: 'group',
    permission: ['admin'],
    children: [
        {
            id: 'settings',
            title: 'menu.setting',
            type: 'item',
            url: '/settings',
            icon: icons.SettingOutlined,
        },
        {
            id: 'users',
            title: 'menu.users',
            type: 'item',
            url: '/users',
            icon: icons.TeamOutlined,
        },
        {
            id: 'documentation',
            title: 'menu.manual',
            type: 'item',
            url: 'https://www.ey.com/cs_cz/workey',
            icon: icons.QuestionCircleOutlined,
            external: true,
            target: true,
        },
    ],
};

export default admin;
