// assets
import {
    DashboardOutlined,
    LoginOutlined,
    PlusOutlined,
    DollarCircleOutlined,
    GlobalOutlined,
    SmileOutlined,
} from '@ant-design/icons';

// icons
const icons = {
    GlobalOutlined,
    DashboardOutlined,
    DollarCircleOutlined,
    LoginOutlined,
    PlusOutlined,
    SmileOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const pages = {
    id: 'group-dashboard',
    type: 'group',
    permission: ['user', 'manager', 'admin'],
    children: [
        {
            id: 'dashboard',
            title: 'menu.dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardOutlined,
            breadcrumbs: false,
        },
        {
            id: 'leads',
            title: 'menu.leads',
            url: '/lead',
            type: 'item',
            icon: icons.DollarCircleOutlined,
        },
        {
            id: 'clients',
            title: 'menu.clients',
            type: 'item',
            url: '/clients',
            icon: icons.SmileOutlined,
        },
        {
            id: 'businesscases',
            title: 'menu.bc',
            type: 'item',
            url: '/businesscases',
            icon: icons.GlobalOutlined,
        },
    ],
};

export default pages;
