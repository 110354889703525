// import axios from 'axios';
import { UsersApi } from '@workey/workeysales-client-api';
import api, { checkAndUpdateAccessToken } from './http.service';
import apiConfiguration from '../apiConfig';

const usersApi = new UsersApi(apiConfiguration, undefined, api);

const getCurrentUser = () => {
    if (!localStorage.getItem('user') || localStorage.getItem('user') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('user'));
};

const setUser = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
};

const setLanguage = (lang) => {
    localStorage.setItem('lang', lang);
};

const getLanguage = () => {
    if (!localStorage.getItem('lang') || localStorage.getItem('lang') === 'undefined') return 'cs';
    return localStorage.getItem('lang');
};

const userUpdate = async (id, userData, self = false) => {
    await checkAndUpdateAccessToken();
    return usersApi.patchUser(id, userData).then(
        (response) => {
            if (self) setUser(response.data);
            return response.data;
        },
    );
};

const createUser = async (userData) => {
    await checkAndUpdateAccessToken();
    return usersApi.addUser(userData).then((response) => response.data);
};

const getUsers = async ({
    sortBy, dir, limit, search, role, page, isActive,
}) => {
    await checkAndUpdateAccessToken();
    return usersApi.getUsers(
        search !== '' ? search : undefined,
        role !== '' ? role : undefined,
        sortBy !== '' && sortBy !== undefined && dir !== '' && dir !== undefined ? `${sortBy}:${dir}` : undefined,
        limit !== '' ? limit : undefined,
        page !== '' ? page : undefined,
        isActive,
    ).then((response) => response.data);
};

const getUser = async (id) => {
    await checkAndUpdateAccessToken();
    return usersApi.getUser(id).then((response) => response.data);
};

const activateUser = async (id) => {
    await checkAndUpdateAccessToken();
    return usersApi.activateUser(id).then((response) => response.data);
};

const deactivateUser = async (id, destId) => {
    await checkAndUpdateAccessToken();
    const body = {
        clientSubstituteUser: destId === '' ? undefined : destId,
    };
    return usersApi.deactivateUser(id, body).then((response) => response.data);
};

const deleteUser = async (id) => {
    await checkAndUpdateAccessToken();
    return usersApi.deleteUser(id).then((response) => response.data);
};

const UserService = {
    getLanguage,
    setLanguage,
    createUser,
    getCurrentUser,
    userUpdate,
    setUser,
    getUsers,
    getUser,
    activateUser,
    deactivateUser,
    deleteUser,
};

export default UserService;
