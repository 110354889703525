/* eslint-disable default-param-last */
import { auth as a } from '../actions/types';
import TokenService from '../services/token.service';

const oldTokens = TokenService.getTokens() === null ? {} : TokenService.getTokens();

export default function tokens(state = oldTokens, action) {
    const { type, payload } = action;
    switch (type) {
    case a.LOGIN_SUCCESS:
        return {
            ...state,
            access: payload.data.tokens.access,
            refresh: payload.data.tokens.refresh,
        };
    case a.ACCESS_TOKEN_UPDATE:
        return {
            ...state,
        };
    default:
        return state;
    }
}
