/* eslint-disable default-param-last */
import { enums as e } from '../actions/types';

const oldState = {
};

export default function enumerations(state = oldState, action) {
    const { type, payload, enumType } = action;
    switch (type) {
    case e.ENUMERATIONS_RECEIVED:
        return {
            ...state,
            enums: payload.data,
        };
    case e.ENUMERATIONTYPE_RECEIVED:
        return {
            ...state,
            [enumType]: payload.data,
        };
    case e.SET_ENUM:
        return {
            ...state,
            currentEnum: payload,
        };
    default:
        return state;
    }
}
