/* eslint-disable default-param-last */
import { businesscases as b } from '../actions/types';

const oldState = {
    currentBs: { constructionAddress: {} },
};

export default function businesscase(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case b.SET_FILTERS:
        return {
            ...state,
            filters: payload,
        };
    case b.CLEAR_CURRENTBUSINESSCASE:
        delete state.currentBs;
        return state;
    case b.BUSINESSCASE_FAIL:
        return {
            ...state,
        };
    case b.BUSINESSCASE_SUCCESS:
        return {
            ...state,
        };
    case b.BUSINESSCASES_RECEIVED:
        return {
            ...state,
            businesscases: payload.data,
        };
    case b.BUSINESSCASE_RECEIVED:
        return {
            ...state,
            currentBs: payload.data,
        };
    case b.BUSINESSSTATES_RECEIVED:
        return {
            ...state,
            businessStates: payload.data,
        };
    case b.SET_BUSINESSCASE:
        return {
            ...state,
            currentBs: payload,
        };
    case b.SET_NEW_BUSINESSCASE:
        return {
            ...state,
            newBusinessCase: payload,
        };
    default:
        return state;
    }
}
