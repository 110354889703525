/* eslint-disable default-param-last */
import { statistics as s } from '../actions/types';

const oldState = {
};

export default function statistics(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case s.STATS_CLIENT:
        return {
            ...state,
            client: payload.data,
        };
    case s.STATS_LEAD:
        return {
            ...state,
            lead: payload.data,
        };
    case s.STATS_OFFER:
        return {
            ...state,
            offer: payload.data,
        };
    default:
        return state;
    }
}
