import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from '../../../config';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

function MainDrawer({ open, handleDrawerToggle, window }) {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('xl'));

    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;

    // header content
    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { md: 0 },
                zIndex: 1300,
                backgroundColor: theme.palette.menu.background,
                '& .MuiDrawer-paper': {
                    backgroundColor: theme.palette.menu.background,
                },
            }}
            aria-label="mailbox folders"
        >
            {!matchDownMD ? (
                <MiniDrawerStyled variant="permanent" open={open}>
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClick={handleDrawerToggle}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', xl: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            boxShadow: 'inherit',
                            backgroundColor: theme.palette.menu.background,
                        },
                    }}
                >
                    {open && drawerHeader}
                    {open && drawerContent}
                </Drawer>
            )}
        </Box>
    );
}

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object,
};

export default MainDrawer;
