import React, { lazy } from 'react';
import { Navigate } from 'react-router';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import RequireAuth from '../providers/RequireAuth';
import MetatagTitle from '../providers/MetatagTitle';
import BreadcrumbsProvider from '../providers/BreadcrumbsProvider';

// breadcrumbs data
import breadcrumbs from './breadcrumbs';

// render - dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const ClientsList = Loadable(lazy(() => import('../pages/clients/list/ClientsList')));
const BusinessCases = Loadable(lazy(() => import('../pages/businesscases/list/BusinessCasesList')));
const Client = Loadable(lazy(() => import('../pages/clients/detail/Client')));
const UsersList = Loadable(lazy(() => import('../pages/users/list/UsersList')));
const Profile = Loadable(lazy(() => import('../pages/profile/profile')));
const LeadsList = Loadable(lazy(() => import('../pages/leads/list/LeadsList')));
const Lead = Loadable(lazy(() => import('../pages/leads/detail/Lead')));
const BusinessCase = Loadable(lazy(() => import('../pages/businesscases/detail/BusinessCase')));
const Offer = Loadable(lazy(() => import('../pages/offer/detail/Offer')));
const NewBusinessOffer = Loadable(lazy(() => import('../pages/offer/new/NewBusinessOffer')));
const Settings = Loadable(lazy(() => import('../pages/settings/main/Main')));
const Categories = Loadable(lazy(() => import('../pages/settings/categories/list/CategoriesList')));
const Items = Loadable(lazy(() => import('../pages/settings/items/list/ItemsList')));
const OfferSets = Loadable(lazy(() => import('../pages/settings/offersets/list/OfferSetsList')));
const OfferSetsEditor = Loadable(lazy(() => import('../pages/settings/offersets/editor/OfferSetsEditor')));
const Tags = Loadable(lazy(() => import('../pages/settings/tags/list/TagsList')));
const EnumerationsEditor = Loadable(lazy(() => import('../pages/settings/enumerations/editor/EnumerationsEditor')));
const OptionsEditor = Loadable(lazy(() => import('../pages/settings/options/editor/OptionsEditor')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <RequireAuth><MainLayout /></RequireAuth>,
    children: [
        {
            path: '*',
            element: <Navigate to="/dashboard" replace />,
        },
        {
            path: 'dashboard',
            element: (
                <MetatagTitle title="header.dashboard">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.dashboard} activateItem="dashboard">
                        <Dashboard />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: '',
            element: (
                <MetatagTitle title="header.dashboard">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.dashboard} activateItem="dashboard">
                        <Dashboard />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'settings',
            element: (
                <MetatagTitle title="header.settings">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.settings} activateItem="settings">
                        <Settings />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'client/:id',
            element: (
                <MetatagTitle title="header.clients">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.clientDetail} activateItem="clients">
                        <Client />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'clients',
            element: (
                <MetatagTitle title="header.clients">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.clientList} activateItem="clients">
                        <ClientsList />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'lead',
            element: (
                <MetatagTitle title="header.lead">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.leadsList} activateItem="leads">
                        <LeadsList />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'lead/:id',
            element: (
                <MetatagTitle title="header.lead">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.leadDetail} activateItem="leads">
                        <Lead />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'users',
            element: (
                <MetatagTitle title="header.users">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.usersList} activateItem="users">
                        <UsersList />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'businesscases',
            element: (
                <MetatagTitle title="header.businesscase">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.businessCases} activateItem="businesscases">
                        <BusinessCases />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'businesscase/:id',
            element: (
                <MetatagTitle title="header.businesscase">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.businessCase} activateItem="businesscases">
                        <BusinessCase />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'offer/:id',
            element: (
                <MetatagTitle title="header.offer">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.offerDetail} activateItem="businesscases">
                        <Offer />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'offer/new',
            element: (
                <MetatagTitle title="header.offer">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.newOffer} activateItem="businesscases">
                        <NewBusinessOffer />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'profile',
            element: (
                <MetatagTitle title="header.profile">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.profile} activateItem="users">
                        <Profile />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'categories',
            element: (
                <MetatagTitle title="header.categories">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.categories} activateItem="settings">
                        <Categories />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'items',
            element: (
                <MetatagTitle title="header.items">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.items} activateItem="settings">
                        <Items />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'offersets',
            element: (
                <MetatagTitle title="header.offer">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.offersets} activateItem="settings">
                        <OfferSets />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'offersetseditor',
            element: (
                <MetatagTitle title="header.offer">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.offersetseditor} activateItem="settings">
                        <OfferSetsEditor />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'tags',
            element: (
                <MetatagTitle title="header.tag">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.tags} activateItem="settings">
                        <Tags />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'enumerations',
            element: (
                <MetatagTitle title="header.enumerations">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.enumerations} activateItem="settings">
                        <EnumerationsEditor />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
        {
            path: 'options',
            element: (
                <MetatagTitle title="header.options">
                    <BreadcrumbsProvider breadcrumbs={breadcrumbs.options} activateItem="settings">
                        <OptionsEditor />
                    </BreadcrumbsProvider>
                </MetatagTitle>
            ),
        },
    ],
};

export default MainRoutes;
