import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import UserService from './services/user.service';

import translationEN from './locales/en/translation.json';
import translationCS from './locales/cs/translation.json';

const resources = {
    cs: {
        translation: translationCS,
    },
    en: {
        translation: translationEN,
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: UserService.getLanguage(),
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
