/* eslint-disable default-param-last */
import { message as m } from '../actions/types';
import translate from '../services/message.service';
import getters from '../utils/getters';

const initialState = { msgQueue: [] };
export default function message(state = initialState, action) {
    const { type, payload, severity } = action;
    switch (type) {
    case m.SET_MESSAGE: {
        const msgData = {
            type: 'localNotification',
            data: { id: getters.randomId(10), message: translate(payload), confirmation: false },
            severity,
        };

        const unique = state?.msgQueue.filter((i) => i.id === payload.id).length === 0;
        if (state?.msgQueue.length > 0 && unique) {
            const msgQueue = [...state.msgQueue, msgData];
            return {
                ...state,
                msgQueue,
            };
        }

        const msgQueue = [msgData];
        return {
            ...state,
            msgQueue,
        };
    }
    case m.ADD_MESSAGE: {
        const msgData = {
            type: 'notification',
            data: payload,
            severity,
        };

        const unique = state?.msgQueue.filter((i) => i.id === payload.id).length === 0;
        if (state?.msgQueue.length > 0 && unique) {
            const msgQueue = [...state.msgQueue, msgData];
            return {
                ...state,
                msgQueue,
            };
        }

        const msgQueue = [msgData];
        return {
            ...state,
            msgQueue,
        };
    }
    case m.REMOVE_MESSAGE:
        if (state.msgQueue.length > 0) {
            state.msgQueue = state.msgQueue.filter((i) => i.id !== payload);
        }
        return state;
    case m.CLEAR_MESSAGES:
        return {
            snackbarForceClose: true,
            msgQueue: [],
        };
    case m.CANCEL_FORCE_CLOSE:
        return {
            ...state,
            snackbarForceClose: false,
        };
    default:
        return state;
    }
}
