import { AuthApi } from '@workey/workeysales-client-api';
import api from './http.service';
import TokenService from './token.service';
import apiConfiguration from '../apiConfig';

const authApi = new AuthApi(apiConfiguration, undefined, api);

const login = (email, password) => authApi.login({ email, password });

const logout = async () => {
    const token = await TokenService.getLocalRefreshToken();
    localStorage.clear();
    sessionStorage.clear();
    TokenService.removeUser();
    return authApi.logout({ refreshToken: await token });
};

const forgotPassword = (email) => authApi.forgotPassword({ email });

const resetPassword = (password, token) => authApi.resetPassword(token, { password });

const AuthService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
};

export default AuthService;
