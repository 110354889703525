import {
    message as m,
    reload as r,
} from '../actions/types';

export const reload = (dispatch) => dispatch({ type: r.SET_RELOAD });

export const notification = (dispatch, message, type) => {
    dispatch({
        type: m.SET_MESSAGE,
        payload: message,
        severity: type,
    });
};

export const handleErrorMessage = (dispatch, error, failType) => {
    // eslint-disable-next-line
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    dispatch({ type: failType });
    notification(dispatch, message, 'error');
    reload(dispatch);
    return Promise.reject();
};

export const handleImportError = (dispatch, error, failType) => {
    dispatch({ type: failType });
    notification(dispatch, 'tables.importCsvModal.importError', 'error');
    reload(dispatch);
    return Promise.reject();
};
