// auth
export const auth = {
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAIL: 'REGISTER_FAIL',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGOUT: 'LOGOUT',
    PSWD_CORRECT: 'PSWD_CORRECT',
    PSWD_INCORRECT: 'PSWD_INCORRECT',
    ACCESS_TOKEN_UPDATE: 'ACCESS_TOKEN_UPDATE',
    AUTH_CLEAR: 'AUTH_CLEAR',
};
// user
export const user = {
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',
    USER_CREATED: 'USER_CREATED',
    USER_FAILED: 'USER_FAILED',
    USER_SUCCESS: 'USER_SUCCESS',
    USERS_SUCCESS_ALTERNATIVE: 'USERS_SUCCESS_ALTERNATIVE',
    USERS_SUCCESS: 'USERS_SUCCESS',
    USERS_FAILED: 'USERS_FAILED',
    USER_ACTIVATED: 'USER_ACTIVATED',
    USER_DEACTIVATED: 'USER_ACTIVATED',
    USER_DELETED: 'USER_DELETED',
    SET_NEW_USER: 'SET_NEW_USER',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
};
// tags
export const tags = {
    TAG_UPDATE_SUCCESS: 'TAG_UPDATE_SUCCESS',
    TAG_UPDATE_FAIL: 'TAG_UPDATE_FAIL',
    TAG_CREATED: 'TAG_CREATED',
    TAG_FAILED: 'TAG_FAILED',
    TAG_SUCCESS: 'TAG_SUCCESS',
    TAGS_SUCCESS: 'TAGS_SUCCESS',
    TAGS_FAILED: 'TAGS_FAILED',
    SET_NEW_TAG: 'SET_NEW_TAG',
    SET_CURRENT_TAG: 'SET_CURRENT_TAG',
};
// message
export const message = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    SET_MESSAGE: 'SET_MESSAGE',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
    REMOVE_MESSAGE: 'REMOVE_MESSAGE',
    CANCEL_FORCE_CLOSE: 'CANCEL_FORCE_CLOSE',
};
// sse
export const sse = {
    LAST_EVENT: 'LAST_EVENT',
};
// reload
export const reload = {
    SET_RELOAD: 'SET_RELOAD',
};
// error
export const error = {
    SET_ERROR: 'SET_ERROR',
    SET_NEW_BC_ERROR: 'SET_NEW_BC_ERROR',
};
// breadcrumbs
export const breadcrumbs = {
    SET_BREADCRUMBS: 'SET_BREADCRUMBS',
    SET_LAST_CLIENT: 'SET_LAST_CLIENT',
    SET_LAST_LEAD: 'SET_LAST_LEAD',
    SET_LAST_BC: 'SET_LAST_BC',
};
// client
export const client = {
    CLIENT_SUCCESS: 'CLIENT_SUCCESS',
    CLIENT_FAIL: 'CLIENT_FAILED',
    CLIENT_CLEAR: 'CLIENT_CLEAR',
    CLIENTS_RECEIVED: 'CLIENTS_RECEIVED',
    CLIENT_RECEIVED: 'CLIENT_RECEIVED',
    LEADS_RECEIVED: 'LEADS_RECEIVED',
    CLIENT_UPDATED: 'CLIENT_UPDATED',
    CLIENT_UPDATE_FAIL: 'CLIENT_UPDATE_FAIL',
    CLIENT_STATE_SUCCESS: 'CLIENT_STATE_SUCCESS',
    CLIENT_STATE_FAIL: 'CLIENT_STATE_FAIL',
    CLIENT_STATES_RECEIVED: 'CLIENT_STATES_RECEIVED',
    CLIENT_LOG_RECEIVED: 'CLIENT_LOG_RECEIVED',
    CLIENT_LOG_FAIL: 'CLIENT_LOG_FAIL',
    CLEAR_CURRENTCLIENT: 'CLEAR_CURRENTCLIENT',
    SET_CLIENT: 'SET_CLIENT',
    SET_NEW_CLIENT: 'SET_NEW_CLIENT',
    SET_FILTERS: 'SET_FILTERS',
    CLIENTS_EXPORTED: 'CLIENTS_EXPORTED',
};
// businesscase
export const businesscases = {
    BUSINESSCASES_EXPORTED: 'BUSINESSCASES_EXPORTED',
    BUSINESSCASE_RECEIVED: 'BUSINESSCASE_RECEIVED',
    BUSINESSCASES_RECEIVED: 'BUSINESSCASES_RECEIVED',
    BUSINESSCASE_SUCCESS: 'BUSINESSCASE_SUCCESS',
    BUSINESSCASE_FAIL: 'BUSINESSCASE_FAIL',
    BUSINESSCASE_UPDATED: 'BUSINESSCASE_UPDATED',
    BUSINESSCASE_UPDATE_FAIL: 'BUSINESSCASE_UPDATE_FAIL',
    BUSINESSSTATES_RECEIVED: 'BUSINESSSTATES_RECEIVED',
    CLEAR_CURRENTBUSINESSCASE: 'CLEAR_CURRENTBUSINESSCASE',
    SET_NEW_BUSINESSCASE: 'SET_NEW_BUSINESSCASE',
    SET_BUSINESSCASE: 'SET_BUSINESSCASE',
    SET_FILTERS: 'SET_FILTERS',
};
// businessoffer
export const businessoffer = {
    BUSINESSOFFER_RECEIVED: 'BUSINESSOFFER_RECEIVED',
    BUSINESSOFFERS_RECEIVED: 'BUSINESSOFFERS_RECEIVED',
    BUSINESSOFFER_SUCCESS: 'BUSINESSOFFER_SUCCESS',
    BUSINESSOFFER_FAIL: 'BUSINESSCASE_FAIL',
    BUSINESSOFFERSET_RECEIVED: 'BUSINESSOFFERSET_RECEIVED',
    BUSINESSOFFERSETS_RECEIVED: 'BUSINESSOFFERSETS_RECEIVED',
    BUSINESSOFFERSET_SUCCESS: 'BUSINESSOFFERSET_SUCCESS',
    BUSINESSOFFERSET_FAIL: 'BUSINESSOFFERSET_FAIL',
    BUSINESSOFFERSET_UPDATED: 'BUSINESSOFFERSET_UPDATED',
    BUSINESSOFFERSET_UPDATE_FAIL: 'BUSINESSOFFERSET_UPDATE_FAIL',
    BUSINESSOFFERSTATES_RECEIVED: 'BUSINESSOFFERSTATES_RECEIVED',
    BUSINESSOFFER_UPDATED: 'BUSINESSOFFER_UPDATED',
};
// notes
export const notes = {
    NOTE_SUCCESS: 'NOTE_SUCCESS',
    NOTE_FAIL: 'NOTE_FAIL',
    SET_NOTE: 'SET_NOTE',
};
// enumerations
export const enums = {
    SET_ENUM: 'SET_ENUM',
    ENUMERATION_FAIL: 'ENUMERATION_FAIL',
    ENUMERATION_CREATED: 'ENUMERATION_CREATED',
    ENUMERATION_UPDATED: 'ENUMERATION_UPDATED',
    ENUMERATIONS_RECEIVED: 'ENUMERATIONS_RECEIVED',
    ENUMERATIONTYPE_RECEIVED: 'ENUMERATIONTYPE_RECEIVED',
    SET_ENUM_NAME: 'SET_ENUM_NAME',
    SET_ENUM_MODAL_ID: 'SET_ENUM_MODAL_ID',
};
// options
export const options = {
    SET_OPTION_CATEGORY: 'SET_OPTION_CATEGORY',
    OPTION_FAIL: 'OPTION_FAIL',
    OPTIONS_FAIL: 'OPTIONS_FAIL',
    OPTION_CREATED: 'OPTION_CREATED',
    OPTION_UPDATED: 'OPTION_UPDATED',
    OPTION_RECEIVED: 'OPTION_RECEIVED',
    OPTIONS_RECEIVED: 'OPTIONS_RECEIVED',
};
// offer items
export const offeritems = {
    OFFERITEMS_RECEIVED: 'OFFERITEMS_RECEIVED',
    OFFERITEM_SUCCESS: 'OFFERITEMS_SUCCESS',
    OFFERITEM_FAIL: 'OFFERITEMS_FAIL',
    OFFERITEM_RECEIVED: 'OFFERITEM_RECEIVED',
    OFFERITEM_UPDATED: 'OFFERITEM_UPDATED',
    OFFERITEM_UPDATE_FAIL: 'OFFERITEM_UPDATE_FAIL',
    SET_NEW_OFFERITEM: 'SET_NEW_OFFERITEM',
    SET_OFFERITEM: 'SET_OFFERITEM',
    OFFERITEMCATEGORIES_RECEIVED: 'OFFERITEMCATEGORIES_RECEIVED',
    OFFERITEMCATEGORY_RECEIVED: 'OFFERITEMCATEGORY_RECEIVED',
    OFFERITEMCATEGORY_SUCCESS: 'OFFERITEMCATEGORIES_SUCCESS',
    OFFERITEMCATEGORY_FAIL: 'OFFERITEMCATEGORIES_FAIL',
    OFFERITEMCATEGORY_UPDATED: 'OFFERITEMCATEGORY_UPDATED',
    OFFERITEMCATEGORY_UPDATE_FAIL: 'OFFERITEMCATEGORY_UPDATE_FAIL',
    SET_NEW_OFFERITEMCATEGORY: 'SET_NEW_OFFERITEMCATEGORY',
    SET_OFFERITEMCATEGORY: 'SET_OFFERITEMCATEGORY',
    OFFERITEM_IMPORT: 'OFFERITEM_IMPORT',
    OFFERITEM_IMPORT_FAIL: 'OFFERITEM_IMPORT_FAIL',
};
// offer item sets
export const offeritemset = {
    OFFERITEMSET_RECEIVED: 'OFFERITEMSET_RECEIVED',
    OFFERITEMSET_SUCCESS: 'OFFERITEMSET_SUCCESS',
    OFFERITEMSET_FAIL: 'OFFERITEMSET_FAIL',
    OFFERITEMSETS_RECEIVED: 'OFFERITEMSETS_RECEIVED',
    OFFERITEMSET_UPDATED: 'OFFERITEMSET_UPDATED',
    OFFERITEMSET_UPDATE_FAIL: 'OFFERITEMSET_UPDATE_FAIL',
    SET_ITEMS_CATEGORY: 'SET_ITEMS_CATEGORY',
    SET_CURRENT_SET_ITEMS: 'SET_CURRENT_SET_ITEMS',
    SET_CATEGORY_OF_ITEMS: 'SET_CATEGORY_OF_ITEMS',
    SET_CURRENT_SET_ID: 'SET_CURRENT_SET_ID',
    SET_NEW_SET_ITEM: 'SET_NEW_SET_ITEM',
    SET_NEW_SET: 'SET_NEW_SET',
    SET_CURRENT_SET: 'SET_CURRENT_SET',
};
// documents
export const documents = {
    DOCUMENT_SUCCESS: 'DOCUMENT_SUCCESS',
    DOCUMENT_FAIL: 'DOCUMENT_FAIL',
    DOCUMENTS_RECEIVED: 'DOCUMENTS_RECEIVED',
    OFFER_DOCUMENTS_RECEIVED: 'OFFER_DOCUMENTS_RECEIVED',
    DOCUMENT_RECEIVED: 'DOCUMENT_RECEIVED',
    AVAILABLE_DOCUMENTS_RECEIVED: 'AVAILABLE_DOCUMENTS_RECEIVED',
    AVAILABLE_DOCUMENTS_RECEIVED_OFFER: 'AVAILABLE_DOCUMENTS_RECEIVED_OFFER',
    AVAILABLE_DOCUMENTS_RECEIVED_CLIENT: 'AVAILABLE_DOCUMENTS_RECEIVED_CLIENT',
    DOCUMENT_GENERATING: 'DOCUMENT_GENERATING',
    SORT_DOCUMENTS: 'SORT_DOCUMENTS',
    SET_CSV_FILE: 'SET_CSV_FILE',
};
export const statistics = {
    STATS_CLIENT: 'STATS_CLIENT',
    STATS_LEAD: 'STATS_LEAD',
    STATS_OFFER: 'STATS_OFFER',
};
// loading
export const loading = {
    CLEAR_LOADERS: 'CLEAR_LOADERS',
    LOADING_GETCLIENTS: 'LOADING_GETCLIENTS',
    LOADED_GETCLIENTS: 'LOADED_GETCLIENTS',
    LOADING_GETCLIENT: 'LOADING_GETCLIENT',
    LOADED_GETCLIENT: 'LOADED_GETCLIENT',
    LOADING_GETCLIENTSTATES: 'LOADING_GETCLIENTSTATES',
    LOADED_GETCLIENTSTATES: 'LOADED_GETCLIENTSTATES',
    LOADING_GETUSERS: 'LOADING_GETUSERS',
    LOADED_GETUSERS: 'LOADED_GETUSERS',
    LOADING_GETUSER: 'LOADING_GETUSER',
    LOADED_GETUSER: 'LOADED_GETUSER',
    LOADING_GETTAGS: 'LOADING_GETTAGS',
    LOADED_GETTAGS: 'LOADED_GETTAGS',
    LOADING_GETTAG: 'LOADING_GETTAG',
    LOADED_GETTAG: 'LOADED_GETTAG',
    LOADING_GETCLIENTLOG: 'LOADING_GETCLIENTLOG',
    LOADED_GETCLIENTLOG: 'LOADED_GETCLIENTLOG',
    LOADING_GETBUSINESSCASE: 'LOADING_BUSINESSCASE',
    LOADED_GETBUSINESSCASE: 'LOADED_BUSINESSCASE',
    LOADING_GETBUSINESSCASES: 'LOADING_BUSINESSCASES',
    LOADED_GETBUSINESSCASES: 'LOADED_BUSINESSCASES',
    LOADING_GETOFFER: 'LOADING_GETOFFER',
    LOADED_GETOFFER: 'LOADED_GETOFFER',
    LOADING_GETOFFERS: 'LOADING_GETOFFERS',
    LOADED_GETOFFERS: 'LOADED_GETOFFERS',
    LOADING_BUSINESSOFFERSET: 'LOADING_BUSINESSOFFERSET',
    LOADED_BUSINESSOFFERSET: 'LOADED_BUSINESSOFFERSET',
    LOADING_BUSINESSOFFERSETS: 'LOADING_BUSINESSOFFERSET',
    LOADED_BUSINESSOFFERSETS: 'LOADED_BUSINESSOFFERSET',
    LOADING_ENUMERATIONS: 'LOADING_ENUMERATIONS',
    LOADED_ENUMERATIONS: 'LOADED_ENUMERATIONS',
    LOADING_ENUMERATIONTYPE: 'LOADING_ENUMERATIONTYPE',
    LOADED_ENUMERATIONTYPE: 'LOADED_ENUMERATIONTYPE',
    LOADING_OFFERITEMCATEGORIES: 'LOADING_OFFERITEMCATEGORIES',
    LOADED_OFFERITEMCATEGORIES: 'LOADED_OFFERITEMCATEGORIES',
    LOADING_OFFERITEMCATEGORY: 'LOADING_OFFERITEMCATEGORY',
    LOADED_OFFERITEMCATEGORY: 'LOADED_OFFERITEMCATEGORY',
    LOADING_OFFERITEMS: 'LOADING_OFFERITEMS',
    LOADED_OFFERITEMS: 'LOADED_OFFERITEMS',
    LOADING_OFFERITEM: 'LOADING_OFFERITEM',
    LOADED_OFFERITEM: 'LOADED_OFFERITEM',
    LOADING_GETBUSINESSSTATES: 'LOADING_GETBUSINESSSTATES',
    LOADED_GETBUSINESSSTATES: 'LOADED_GETBUSINESSSTATES',
    LOADING_OFFERITEMSET: 'LOADING_OFFERITEMSET',
    LOADED_OFFERITEMSET: 'LOADED_OFFERITEMSET',
    LOADING_OFFERITEMSETS: 'LOADING_OFFERITEMSETS',
    LOADED_OFFERITEMSETS: 'LOADED_OFFERITEMSETS',
    LOADING_DOCUMENTS: 'LOADING_DOCUMENTS',
    LOADED_DOCUMENTS: 'LOADED_DOCUMENTS',
    LOADING_AVAILABLEDOCUMENT: 'LOADING_AVAILABLE_DOCUMENT',
    LOADED_AVAILABLEDOCUMENT: 'LOADED_AVAILABLE_DOCUMENT',
    LOADING_DOCUMENT: 'LOADING_DOCUMENT',
    LOADED_DOCUMENT: 'LOADED_DOCUMENT',
    LOADING_CLIENTSTATS: 'LOADING_CLIENTSTATS',
    LOADED_CLIENTSTATS: 'LOADED_CLIENTSTATS',
    LOADING_LEADSTATS: 'LOADING_LEADSTATS',
    LOADED_LEADSTATS: 'LOADED_LEADSTATS',
    LOADING_OFFERSTATS: 'LOADING_OFFERSTATS',
    LOADED_OFFERSTATS: 'LOADED_OFFERSTATS',
    LOADING_BUSINESSOFFERSTATES: 'LOADING_BUSINESSOFFERSTATES',
    LOADED_BUSINESSOFFERSTATES: 'LOADED_BUSINESSOFFERSTATES',
    LOADING_OPTIONS: 'LOADING_OPTIONS',
    LOADED_OPTIONS: 'LOADED_OPTIONS',
    LOADING_OPTION: 'LOADING_OPTION',
    LOADED_OPTION: 'LOADED_OPTION',
};
