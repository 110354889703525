import dictionary from '../constants/dictionary';

const translate = (msg) => {
    let translateMsg = msg;
    dictionary.forEach((d) => {
        if (typeof translateMsg === 'string') {
            translateMsg = translateMsg.replace(d.original, d.translated);
        }
    });
    return translateMsg;
};
export default translate;
