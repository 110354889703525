const dictionary = [
    {
        original: '"email" must be a valid email',
        translated: 'Email musí mít správný tvar',
    },
    {
        original: '"firstName" is not allowed to be empty',
        translated: 'Křestní jméno musí být vyplněno',
    },
    {
        original: '"phone" is not allowed to be empty',
        translated: 'Telefonní číslo musí být vyplněno',
    },
    {
        original: '"lastName" is not allowed to be empty',
        translated: 'Příjmení musí být vyplněno',
    },
    {
        original: '"street" is not allowed to be empty',
        translated: 'Ulice musí být vyplněna',
    },
    {
        original: '"number" is not allowed to be empty',
        translated: 'Číslo popisné musí být vyplněno',
    },
    {
        original: ' "email" is not allowed to be empty',
        translated: 'Email musí být vyplněn',
    },
    {
        original: 'Email already taken',
        translated: 'Email už je zabraný',
    },
    {
        original: 'Invalid email or password',
        translated: 'Nesprávný email nebo heslo.',
    },
    {
        original: 'Incorrect email or password',
        translated: 'Nesprávný email nebo heslo.',
    },
    {
        original: 'Network error',
        translated: 'Chyba připojení',
    },
    {
        original: 'Request failed with status code 404',
        translated: 'Připojení aktuálně není možné. Zkuste to znovu, nebo kontaktujte správce.',
    },
    {
        original: 'Password reset failed',
        translated: 'Reset hesla se nezdařil',
    },
    {
        original: 'Forbidden',
        translated: 'Přistup zakázán',
    },
    {
        original: 'Not found',
        translated: 'Nenalezeno',
    },
    {
        original: 'Please authenticate',
        translated: 'Prosím, přihlaste se.',
    },
    {
        original: '"city" is not allowed to be empty',
        translated: 'Město musí být vyplněno',
    },
    {
        original: '"zipCode" is not allowed to be empty',
        translated: 'PSČ musí být vyplněno',
    },
];

export default dictionary;
