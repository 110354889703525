/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import TokenService from './token.service';
import envConfig from '../env-config';

const instance = axios.create({
    baseURL: `${envConfig.basePathApi}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);
instance.interceptors.response.use(
    (res) => res,
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== '/auth/register' && originalConfig.url !== '/auth/login' && err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                const rt = TokenService.getLocalRefreshToken() === null ? '' : TokenService.getLocalRefreshToken();
                try {
                    instance.post('/auth/refresh-tokens', {
                        refreshToken: rt,
                    }).then(
                        (res) => {
                            const { accessToken } = res.data;
                            TokenService.updateLocalAccessToken(accessToken);
                            return instance(originalConfig);
                        },
                        (error) => {
                            if (!window.location.href.match('/resetPassword/')
                            && !window.location.href.match('/forgotPassword/')
                            && !window.location.href.match('/login')
                            && !window.location.href.match('/register')) {
                                TokenService.removeUser();
                                localStorage.clear();
                                window.location.href = '/login';
                            }
                            Promise.reject(error);
                        },
                    );
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    },
);
export default instance;

export const checkAndUpdateAccessToken = async () => {
    const accessExpiry = Date.parse(JSON.parse(localStorage.getItem('tokens')).access.expires);
    const unixTime = Date.now();
    const rt = TokenService.getLocalRefreshToken() === null ? '' : TokenService.getLocalRefreshToken();
    if (unixTime > accessExpiry) {
        await instance.post('/auth/refresh-tokens', { refreshToken: rt })
            .then(
                (res) => {
                    TokenService.setTokens(res.data);
                },
                (err) => {
                    Promise.reject(err);
                },
            );
    }
};
