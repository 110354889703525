import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function MetatagTitle(props) {
    const { t } = useTranslation();

    useEffect(() => {
        if (props?.title != null) {
            document.title = `${t(props.title)} ${t('header.separator')} ${t('header.title')}`;
        } else {
            document.title = t('header.title');
        }
    }, [props]);

    return props.children;
}

export default MetatagTitle;
