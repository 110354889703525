const breadcrumbs = {
    dashboard: [],
    serviceDetail: [],
    clientList: [
        { name: 'home', link: '/' },
        { name: 'clientList' },
    ],
    clientDetail: [
        { name: 'home', link: '/' },
        { name: 'clientList', link: '/clients' },
        { name: 'clientDetail' },
    ],
    leadsList: [
        { name: 'home', link: '/' },
        { name: 'leadsList' },
    ],
    leadDetail: [
        { name: 'home', link: '/' },
        { name: 'leadsList', link: '/lead' },
        { name: 'leadDetail' },
    ],
    businessCases: [
        { name: 'home', link: '/' },
        { name: 'businessCases' },
    ],
    businessCase: [
        { name: 'home', link: '/' },
        { name: 'businessCases', link: '/businesscases' },
        { name: 'businessCase' },
    ],
    offerDetail: [
        { name: 'home', link: '/' },
        { name: 'businessCases', link: '/businesscases' },
        { name: 'businessCase', link: '/businesscase/' },
        { name: 'offerDetail' },
    ],
    newOffer: [
        { name: 'home', link: '/' },
        { name: 'businessCases', link: '/businesscases' },
        { name: 'businessCase', link: '/businesscase/' },
        { name: 'offerNew' },
    ],
    profile: [],
    settings: [
        { name: 'home', link: '/' },
        { name: 'settings' },
    ],
    newClientState: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'newClientState' },
    ],
    categories: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'categories' },
    ],
    items: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'items' },
    ],
    offersets: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'offersets' },
    ],
    offersetseditor: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'offersetseditor' },
    ],
    newtag: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'newtag' },
    ],
    tags: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'tags' },
    ],
    enumerations: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'enumerations' },
    ],
    options: [
        { name: 'home', link: '/' },
        { name: 'settings', link: '/settings' },
        { name: 'options' },
    ],
    usersList: [
        { name: 'home', link: '/' },
        { name: 'usersList' },
    ],
};

export default breadcrumbs;
