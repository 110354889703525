import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

function NavGroup({ item }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const navCollapse = item?.children?.map((menu) => {
        switch (menu.type) {
        case 'item':
            return <NavItem key={menu.id} item={menu} level={1} />;
        default:
            return (
                <Typography key={menu.id} variant="h6" color="error" align="center">
                    Fix - Group Collapse or Items
                </Typography>
            );
        }
    });

    return (
        <List
            subheader={
                item.title && (
                    <Typography
                        variant="subtitle1"
                        color="text.primary"
                        sx={{ color: theme.palette.menu.text.default, pl: 3, mb: 1.5 }}
                    >
                        {t(item.title)}
                    </Typography>
                )
            }
            sx={{ mb: 1 }}

        >
            {navCollapse}
        </List>
    );
}

NavGroup.propTypes = {
    item: PropTypes.object,
};

export default NavGroup;
