// import formatUtils from './format';

const getUsers = (userStorage) => {
    const users = [];
    if (userStorage?.users?.items?.length) {
        userStorage.users.items.forEach((i) => {
            users.push(i);
        });
    }
    return users;
};

const getClients = (clientStorage, userRole) => {
    const clients = [];
    if (clientStorage?.clients?.items?.length) {
        clientStorage.clients.items.forEach((i) => {
            if (!i.isLead && i.clientState.allowedRoles.includes(userRole)) {
                clients.push(i);
            }
        });
    }
    return clients;
};

const getcStates = (client, userRole) => {
    const cStates = [];
    if (client?.clientStates?.items?.length) {
        client.clientStates.items.forEach((i) => {
            if (!i.isLead && i.allowedRoles.includes(userRole)) {
                cStates.push(i);
            }
        });
    }
    return cStates;
};

const getAltcStates = (client, userRole) => {
    const cStates = [];
    const altStates = [];
    if (client?.clientStates?.items?.length) {
        client.clientStates.items.forEach((i) => {
            if (i.isLead && i.allowedRoles.includes(userRole)) {
                cStates.push(i);
            } else {
                altStates.push(i);
            }
        });
    }
    return { cStates, altStates };
};

const getUrlParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
};

const loadersCheck = (loaders, needed) => {
    for (let i = 0; i < needed.length; i += 1) {
        const neededItem = needed[i];
        if (loaders[neededItem] !== true) {
            return false;
        }
    }
    return true;
};

const getSortedDocs = (typesArr, docsArray, setSorted = null) => {
    const sortedDocs = {};
    typesArr.forEach((i) => {
        sortedDocs[i] = docsArray?.filter((k) => k?.documentType?.name === i);
    });
    if (setSorted !== null) {
        setSorted(sortedDocs);
    }
    return sortedDocs;
};

const randomId = (length = 5) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

const getUrlId = () => document.location.href.substring(document.location.href.lastIndexOf('/') + 1);

const getExistingSystem = (currentBusinessCase) => ({
    ean: currentBusinessCase?.existingSystem?.ean,
    supplyPointNumber: currentBusinessCase?.existingSystem?.supplyPointNumber,
    sop: currentBusinessCase?.existingSystem?.sop,
    circuitBreaker: currentBusinessCase?.existingSystem?.circuitBreaker,
    phaseCount: currentBusinessCase?.existingSystem?.phaseCount,
    mainHeatingSource: currentBusinessCase?.existingSystem?.mainHeatingSource,
    hotWaterHeating: currentBusinessCase?.existingSystem?.hotWaterHeating,
    electricityMeterLocation: currentBusinessCase?.existingSystem?.electricityMeterLocation,
    trippingCharacteristic: currentBusinessCase?.existingSystem?.trippingCharacteristic,
    distributor: currentBusinessCase?.existingSystem?.distributor,
    circuitBreakerAmpRating: currentBusinessCase?.existingSystem?.circuitBreakerAmpRating,
    currentInstalledFvePower: currentBusinessCase?.existingSystem?.currentInstalledFvePower,
});

const getNewSystem = (currentBusinessCase) => ({
    circuitBreaker: currentBusinessCase?.newSystem?.circuitBreaker,
    phaseCount: currentBusinessCase?.newSystem?.phaseCount,
    connectionDate: currentBusinessCase?.newSystem?.connectionDate,
    mainHeatingSource: currentBusinessCase?.newSystem?.mainHeatingSource,
    mainHeatingSourcePower: currentBusinessCase?.newSystem?.mainHeatingSourcePower,
    hotWaterHeating: currentBusinessCase?.newSystem?.hotWaterHeating,
    hotWaterHeatingVolume: currentBusinessCase?.newSystem?.hotWaterHeatingVolume,
    sellBackToGrid: currentBusinessCase?.newSystem?.sellBackToGrid,
    circuitBreakerAmpRating: currentBusinessCase?.newSystem?.circuitBreakerAmpRating,
});

const getReservoirs = (currentBusinessCase) => {
    const preppedReservoirs = [];
    const reservoirsStart = currentBusinessCase?.newSystem?.reservoirs;
    if (Array.isArray(reservoirsStart)) {
        reservoirsStart.forEach((i) => {
            let index = 0;
            const piece = {
                index,
                id: i.id,
                reservoirType: i.reservoirType,
                reservoirVolume: i.reservoirVolume,
            };
            preppedReservoirs.push(piece);
            index = preppedReservoirs.length;
        });
    }
    return preppedReservoirs;
};

const getConsents = (currentBusinessCase) => {
    const preppedConsents = [];
    const consentStart = currentBusinessCase?.consentStatements;
    if (Array.isArray(consentStart)) {
        consentStart.forEach((i) => {
            let index = 0;
            const piece = {
                index,
                ownersName: i.ownersName,
                ownersBirthdate: i.ownersBirthdate,
            };
            preppedConsents.push(piece);
            index = preppedConsents.length;
        });
    }
    return preppedConsents;
};

const getNzu = (currentBusinessCase) => ({
    totalUsableGainFve: currentBusinessCase?.nzuData?.totalUsableGainFve,
    heatLoss: currentBusinessCase?.nzuData?.heatLoss,
    realizationStartDate: currentBusinessCase?.nzuData?.realizationStartDate,
    realizationEndDate: currentBusinessCase?.nzuData?.realizationEndDate,
    offerSubsidy: currentBusinessCase?.nzuData?.offerSubsidy,
    numberOfPersons: currentBusinessCase?.nzuData?.numberOfPersons,
});

const getAddressBC = (currentBusinessCase) => ({
    street: currentBusinessCase?.constructionAddress?.street,
    number: currentBusinessCase?.constructionAddress?.number,
    orientationNumber: currentBusinessCase?.constructionAddress?.orientationNumber,
    district: currentBusinessCase?.constructionAddress?.district,
    city: currentBusinessCase?.constructionAddress?.city,
    zipCode: currentBusinessCase?.constructionAddress?.zipCode,
    region: currentBusinessCase?.constructionAddress?.region,
    areaName: currentBusinessCase?.constructionAddress?.areaName,
    areaNumber: currentBusinessCase?.constructionAddress?.areaNumber,
    titleDeed: currentBusinessCase?.constructionAddress?.titleDeed,
    plotNumber: currentBusinessCase?.constructionAddress?.plotNumber,
    country: currentBusinessCase?.constructionAddress?.country,
});

const getObjectSpec = (currentBC) => ({
    roofType: currentBC?.objectSpecification?.roofType?.id,
    objectType: currentBC?.objectSpecification?.objectType?.id,
    roofing: currentBC?.objectSpecification?.roofing?.id,
    requestedLocation: currentBC?.objectSpecification?.requestedLocation?.id,
});

const prepareEnums = (enumTypes, enums, showPrivate = false) => {
    const prepped = [];
    enumTypes?.forEach((et) => {
        const valueNames = enums[et]?.items?.map((i) => i?.name);
        let valueString = '';
        valueNames?.forEach((i) => {
            if ((i?.isPrivate && showPrivate) || !i?.isPrivate) valueString = `${valueString}${i}, `;
        });
        if (valueString?.length) valueString = valueString.substring(0, valueString.length - 2);
        prepped.push({ name: et, valueString, values: enums[et]?.items });
    });

    return prepped;
};

const prepareOptions = (categories, options, showPrivate = false) => {
    const prepped = [];
    if (Array.isArray(categories)) {
        categories?.forEach((cat) => {
            const group = options?.filter((i) => i?.category?.id === cat?.id);
            const valueNames = group?.map((i) => i?.name);
            let valueString = '';
            valueNames?.forEach((i) => {
                if ((i?.isPrivate && showPrivate) || !i?.isPrivate) valueString = `${valueString}${i}, `;
            });
            if (group !== undefined) {
                if (valueString?.length) valueString = valueString.substring(0, valueString.length - 2);
                prepped.push({
                    category: cat?.id, name: cat?.name, values: group, valueString,
                });
            }
        });
    }
    const noCat = options?.filter((i) => i?.category === undefined || i?.category === '');
    const valueNames = noCat?.map((i) => i?.name);
    let valueString = '';
    valueNames?.forEach((i) => { valueString = `${valueString}${i}, `; });
    prepped.push({
        category: '',
        name: 'Nezařazené',
        values: options?.filter((i) => i?.category === undefined || i?.category === ''),
        valueString,
    });
    return prepped;
};

const getCells = (headCells, row) => {
    const retArray = [];
    headCells?.forEach((y) => {
        const idArray = y?.id?.split('&');
        let value = '';

        idArray.forEach((x) => {
            const singular = x.split('.');
            let currentObj = row;
            singular.forEach((i) => {
                currentObj = currentObj[i] ? currentObj[i] : '';
            });
            if (currentObj && typeof currentObj === 'string') value = `${value} ${currentObj}`;
            else value = currentObj;
        });

        retArray.push({
            id: y?.id,
            type: y?.type,
            enumType: y?.enumType,
            align: y?.align,
            disablePadding: y?.disablePadding,
            label: y?.label,
            width: y?.width,
            allowSort: y?.allowSort,
            sortById: y?.sortById,
            value: typeof value === 'string' ? value?.trim() : value,
        });
    });

    return retArray;
};

const getID = (object) => {
    if (typeof object === 'object') return object.id;
    return object;
};

const getters = {
    getID,
    getCells,
    getSortedDocs,
    getUsers,
    getClients,
    getcStates,
    getAltcStates,
    getUrlParam,
    getUrlId,
    loadersCheck,
    randomId,
    getExistingSystem,
    getNewSystem,
    getReservoirs,
    getConsents,
    getNzu,
    getAddressBC,
    getObjectSpec,
    prepareEnums,
    prepareOptions,
};

export default getters;
