// ==============================|| OVERRIDES - TABLE ROW ||============================== //

export default function Table() {
    return {
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root::first-of-type': {
                        pl: 2,
                    },
                    '& .MuiTableCell-root:last-child': {
                        pr: 3,
                    },
                },
            },
        },
    };
}
