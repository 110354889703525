import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/styles';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

// project imports
import MainCard from '../MainCard';

// ==============================|| BREADCRUMBS ||============================== //

function Breadcrumbs({ title, ...others }) {
    const breadcrumbsObject = useSelector((state) => state?.breadcrumbs);
    const breadcrumbs = useSelector((state) => state?.breadcrumbs?.items);
    const theme = useTheme();
    const { t } = useTranslation();

    let breadcrumbContent = <Typography />;

    // main
    if (Array.isArray(breadcrumbs) && breadcrumbs.length) {
        breadcrumbContent = (
            <MainCard border={false} sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item>
                        <MuiBreadcrumbs aria-label="breadcrumb">
                            {Array.isArray(breadcrumbs) && breadcrumbs.map((i) => {
                                if (i?.link && i?.link !== '') {
                                    let transformedLink = '';
                                    switch (i?.name) {
                                    case 'businessCase': {
                                        transformedLink = `${i?.link}${breadcrumbsObject.lastBusinessCase}`;
                                        break;
                                    }
                                    default: {
                                        transformedLink = i?.link;
                                    }
                                    }
                                    return (
                                        <Typography
                                            key={i?.name}
                                            component={Link}
                                            to={transformedLink}
                                            color="textSecondary"
                                            variant="h6"
                                            sx={{ textDecoration: 'none' }}
                                        >
                                            {t(`breadcrumbs.${i?.name}`)}
                                        </Typography>
                                    );
                                }
                                if (i?.link === undefined || i?.link === '') {
                                    return (
                                        <Typography
                                            key={i?.name}
                                            color="textSecondary"
                                            variant="h6"
                                            sx={{
                                                textDecoration: 'none',
                                                fontWeight: 600,
                                                color: theme.palette.grey[800],
                                            }}
                                        >
                                            {t(`breadcrumbs.${i?.name}`)}
                                        </Typography>
                                    );
                                }
                                return null;
                            })}
                        </MuiBreadcrumbs>
                    </Grid>
                </Grid>
            </MainCard>
        );
    }

    return breadcrumbContent;
}

Breadcrumbs.propTypes = {
    title: PropTypes.bool,
};

export default Breadcrumbs;
