/* eslint-disable default-param-last */
import { client as c, notes as n } from '../actions/types';

const oldState = {
    newClient: { constructionAddress: {} },
};

export default function client(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case c.SET_FILTERS:
        return {
            ...state,
            filters: payload,
        };
    case c.SET_CLIENT:
        return {
            ...state,
            currentClient: payload,
        };
    case c.SET_NEW_CLIENT:
        return {
            ...state,
            newClient: payload,
        };
    case c.CLEAR_CURRENTCLIENT:
        delete state.currentClient;
        return state;
    case c.CLIENTS_RECEIVED:
        return {
            ...state,
            clients: payload.data,
        };
    case c.LEADS_RECEIVED:
        return {
            ...state,
            leads: payload.data,
        };
    case c.CLIENT_CLEAR:
        return state;
    case c.CLIENT_SUCCESS:
        return {
            ...state,
            created: true,
        };
    case c.CLIENT_FAIL:
        return {
            ...state,
            created: false,
        };
    case c.CLIENT_STATE_SUCCESS:
        return {
            ...state,
            stateCreated: true,
        };
    case c.CLIENT_STATE_FAIL:
        return {
            ...state,
            stateCreated: false,
        };
    case c.CLIENT_RECEIVED:
        return {
            ...state,
            currentClient: payload.data,
        };
    case c.CLIENT_STATES_RECEIVED:
        return {
            ...state,
            clientStates: payload.data,
        };
    case c.CLIENT_UPDATED:
        return {
            ...state,
            updated: true,
        };
    case c.CLIENT_UPDATE_FAIL:
        return {
            ...state,
            updated: false,
        };
    case c.CLIENT_LOG_RECEIVED:
        return {
            ...state,
            clientLog: payload.data,
        };
    case c.CLIENT_LOG_FAIL:
        return {
            ...state,
            clientLog: null,
        };
    case n.NOTE_SUCCESS:
        return {
            ...state,
            noteCreated: true,
        };
    case n.NOTE_FAIL:
        return {
            ...state,
            noteCreated: false,
        };
    default:
        return state;
    }
}
