// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function MuiCssBaseline(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '&::-webkit-scrollbar': {
                    width: 5,
                    height: 5,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.grey[600],
                    borderRadius: 2,
                },
            },
        },
    };
}
