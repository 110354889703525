import { message as m, sse as s } from './types';
import SSEService from '../services/sse.service';

export const listenForSSE = async (dispatch, eventName, eventPath) => {
    const msg = await SSEService.receiver(eventName, eventPath);
    if (msg?.type === 'notification') {
        dispatch({
            type: m.ADD_MESSAGE,
            payload: msg.data,
            severity: 'success',
        });
        dispatch({
            type: s.LAST_EVENT,
            payload: msg.lastEventId,
        });
    }
};

export const confirmNotification = async (id) => {
    await SSEService.confirmNotification(id);
};
