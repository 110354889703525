const getLocalRefreshToken = () => {
    if (!localStorage.getItem('tokens') || localStorage.getItem('tokens') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('tokens'))?.refresh.token;
};

const getLocalAccessToken = () => {
    if (!localStorage.getItem('tokens') || localStorage.getItem('tokens') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('tokens'))?.access.token;
};

const updateLocalAccessToken = (token) => {
    if (localStorage.getItem('tokens') && localStorage.getItem('tokens') !== 'undefined') {
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        tokens.access.token = token;
        localStorage.setItem('tokens', JSON.stringify(tokens));
    }
};

const getTokens = () => {
    if (!localStorage.getItem('tokens') || localStorage.getItem('tokens') === 'undefined') return null;
    return JSON.parse(localStorage.getItem('tokens'));
};

const setTokens = (data) => localStorage.setItem('tokens', JSON.stringify(data));

const removeUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('tokens');
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getTokens,
    setTokens,
    removeUser,
};
export default TokenService;
