// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableFooter(theme) {
    return {
        MuiTableFooter: {
            styleOverrides: {
                root: {
                    borderTop: `2px solid ${theme.palette.divider}`,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                },
            },
        },
    };
}
