import Color from 'color';
// ==============================|| CUSTOM FUNCTION - COLORS ||============================== //

const getColors = (theme, color) => {
    switch (color) {
    case 'secondary':
        return theme.palette.secondary;
    case 'error':
        return theme.palette.error;
    case 'warning':
        return theme.palette.warning;
    case 'info':
        return theme.palette.info;
    case 'success':
        return theme.palette.success;
    default:
        return theme.palette.primary;
    }
};

const transformColor = (hexColor) => {
    const newColor = Color(hexColor);
    if (newColor.isLight()) {
        return newColor.darken(0.6).hex();
    }
    return newColor.lighten(1).hex();
};

const colorsUtils = {
    getColors,
    transformColor,
};

export default colorsUtils;
