/* eslint-disable default-param-last */
import { documents as d } from '../actions/types';

const oldState = {
};

export default function documents(state = oldState, action) {
    const { type, payload } = action;
    switch (type) {
    case d.AVAILABLE_DOCUMENTS_RECEIVED:
        return {
            ...state,
            availableDocuments: payload.data,
        };
    case d.AVAILABLE_DOCUMENTS_RECEIVED_CLIENT:
        return {
            ...state,
            availableDocumentsClient: payload.data,
        };
    case d.AVAILABLE_DOCUMENTS_RECEIVED_OFFER:
        return {
            ...state,
            availableDocumentsOffer: payload.data,
        };
    case d.DOCUMENTS_RECEIVED:
        return {
            ...state,
            documents: payload.data,
        };
    case d.OFFER_DOCUMENTS_RECEIVED:
        return {
            ...state,
            offerDocuments: payload.data,
        };
    case d.DOCUMENT_SUCCESS:
        return {
            ...state,
            created: true,
        };
    case d.DOCUMENT_RECEIVED:
        return {
            ...state,
            document: payload.data,
        };
    case d.SORT_DOCUMENTS:
        return {
            ...state,
            sorted: payload,
        };
    case d.SET_CSV_FILE:
        return {
            ...state,
            csvFile: payload,
        };
    default:
        return state;
    }
}
