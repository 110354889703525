/* eslint-disable default-param-last */
import { notes as n } from '../actions/types';

const initialState = '';
export default function note(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
    case n.SET_NOTE:
        return payload;
    default:
        return state;
    }
}
