/* eslint-disable default-param-last */
import { reload as r } from '../actions/types';

const initialState = {};
export default function reload(state = initialState, action) {
    const { type } = action;
    switch (type) {
    case r.SET_RELOAD:
        return (Math.random() + 1).toString(36).substring(2);
    default:
        return state;
    }
}
