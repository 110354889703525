/* eslint-disable default-param-last */
import { breadcrumbs as b } from '../actions/types';

const initialState = {};
export default function breadcrumbs(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
    case b.SET_BREADCRUMBS:
        return {
            ...state,
            items: payload || [],
        };
    case b.SET_LAST_CLIENT:
        return {
            ...state,
            lastClient: payload,
        };
    case b.SET_LAST_LEAD:
        return {
            ...state,
            lastLead: payload,
        };
    case b.SET_LAST_BC:
        return {
            ...state,
            lastBusinessCase: payload,
        };
    default:
        return state;
    }
}
