import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RequireAuth({ children }) {
    const user = useSelector((state) => state.user);
    const location = useLocation();

    if (!user.data && window.location.href !== '/login') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}

RequireAuth.propTypes = {
    children: PropTypes.any,
};

export default RequireAuth;
