import { EventSourcePolyfill } from 'event-source-polyfill';
import api, { checkAndUpdateAccessToken } from './http.service';
import TokenService from './token.service';
import { sse as s, message as m } from '../actions/types';

const receiver = async (dispatch, eventName, eventPath) => {
    const sseUrl = `${api.getUri()}${eventPath}`;
    const bearer = `Bearer ${await TokenService.getLocalAccessToken()}`;
    const es = new EventSourcePolyfill(sseUrl, { headers: { Authorization: bearer } });
    es.addEventListener(eventName, (event) => {
        const eventData = {
            type: event.type,
            data: JSON.parse(event.data),
            lastEventId: event.lastEventId,
        };
        if (eventData.type === 'notification') {
            const severity = (
                typeof eventData.data === 'string'
                && eventData.data.match('Failed')
            ) ? 'error' : 'success';

            dispatch({
                type: m.ADD_MESSAGE,
                payload: eventData.data,
                severity,
            });
            dispatch({
                type: s.LAST_EVENT,
                payload: eventData.lastEventId,
            });
        }
    });
    es.addEventListener('error', (event) => {
        // eslint-disable-next-line
        console.log('SSE Error: ', event);
    });
};

const confirmNotification = async (id) => {
    await checkAndUpdateAccessToken();
    await api.post(`/notifications/${id}/confirm`);
};

const SSEService = {
    confirmNotification,
    receiver,
};

export default SSEService;
