import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';

// assets
import {
    EditOutlined, LogoutOutlined,
} from '@ant-design/icons';
import i18n from '../../../../../i18n';

// services
import UserService from '../../../../../services/user.service';
import envConfig from '../../../../../env-config';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

function ProfileTab({ handleLogout }) {
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        if (index === 0) {
            window.location = '/profile';
        }
    };

    const [selectedLng, setSelectedLng] = useState(UserService.getLanguage);

    const changeLanguage = () => {
        if (selectedLng === 'cs') {
            i18n.changeLanguage('en');
            UserService.setLanguage('en');
            setSelectedLng('en');
        } else {
            i18n.changeLanguage('cs');
            UserService.setLanguage('cs');
            setSelectedLng('cs');
        }
    };

    return (
        <List
            component="nav"
            sx={{
                p: 0,
                '& .MuiListItemIcon-root':
        { minWidth: 32, color: theme.palette.grey[500] },
            }}
        >
            <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary={t('profileHeader.show')} />
            </ListItemButton>
            {envConfig.multiLanguage
                ? (
                    <ListItemButton selected={selectedIndex === 1} onClick={() => changeLanguage()}>
                        <ListItemIcon>
                            <EditOutlined />
                        </ListItemIcon>
                        <ListItemText primary={t('profileHeader.translate')} />
                    </ListItemButton>
                ) : null}
            <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary={t('profileHeader.logout')} />
            </ListItemButton>
        </List>
    );
}

ProfileTab.propTypes = {
    handleLogout: PropTypes.func,
};

export default ProfileTab;
