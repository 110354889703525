/* eslint-disable default-param-last */
import { auth as ath } from '../actions/types';

export default function auth(state = {}, action) {
    const { type } = action;
    switch (type) {
    case ath.REGISTER_SUCCESS:
        return {
            reg: true,
        };
    case ath.REGISTER_FAIL:
        return {
            reg: false,
        };
    case ath.AUTH_CLEAR:
        return {};
    default:
        return state;
    }
}
