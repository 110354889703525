import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';
import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// actions
import SSEService from '../services/sse.service';
import { confirmNotification } from '../actions/sse';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [queued, setQueued] = useState([]);
    const storage = useSelector((state) => state);
    const isLoggedIn = storage?.user?.data?.id !== undefined;
    const reload = storage?.reload;
    const forceClose = useSelector((state) => state?.message?.snackbarForceClose);
    const msgQueue = useSelector((state) => state?.message?.msgQueue);
    const msg = useSelector((state) => state?.message);

    useEffect(() => {
        if (isLoggedIn) {
            SSEService.receiver(dispatch, 'notification', '/notifications');
        }
    }, [reload]);

    const notificationCloser = (confirmation, id) => {
        if (confirmation) confirmNotification(id);
        closeSnackbar(id);
    };

    const closeButton = (id, confirmation) => (
        <IconButton size="small" onClick={() => { notificationCloser(confirmation, id); }}>
            <CloseOutlined />
        </IconButton>
    );

    const createSnackbar = (m) => {
        const { type, data, severity } = m;
        const { message, id, confirmation } = data;
        let customOptions = {};
        if (type === 'localNotification') {
            customOptions = {
                key: id,
                autoHideDuration: 5000,
                persist: confirmation,
                variant: severity || 'success',
                onClose: closeSnackbar(id),
                action: () => (closeButton(id, confirmation)),
            };
        } else if (type === 'notification') {
            customOptions = {
                key: id,
                autoHideDuration: 5000,
                persist: confirmation,
                variant: severity || 'success',
                onClose: closeSnackbar(id),
                action: () => (closeButton(id, confirmation)),
            };
        }

        enqueueSnackbar(t(message), customOptions);
    };

    useEffect(() => {
        msgQueue.forEach((i) => {
            if (!queued.includes(i.data.id)) {
                setQueued([...queued, i.data.id]);
                dispatch({
                    type: 'REMOVE_MESSAGE',
                    payload: i.data.id,
                });
                createSnackbar(i);
            }
        });
    }, [msgQueue, msg]);

    useEffect(() => {
        if (forceClose !== undefined) {
            closeSnackbar();
        }
    }, [isLoggedIn]);

    return useRoutes([MainRoutes, LoginRoutes]);
}
