import React from 'react';
// material-ui
import {
    Box, useMediaQuery,
} from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
// TODO notifikace zvonecek header
// import Notification from './Notification';
// pridat za komponentu BOX -> <Notification />

// ==============================|| HEADER - CONTENT ||============================== //

function HeaderContent() {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            <Box sx={{ width: '100%', ml: 1 }} />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );
}

export default HeaderContent;
