import React, { lazy } from 'react';
import { Navigate } from 'react-router';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/MinimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <Navigate to="/login" replace />,
        },
        {
            path: 'login',
            element: <AuthLogin />,
        },
        {
            path: 'forgotPassword',
            element: <ForgotPassword />,
        },
        {
            path: 'resetPassword/:id',
            element: <ResetPassword />,
        },
    ],
};

export default LoginRoutes;
