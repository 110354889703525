import {
    auth as a,
    message as m,
} from './types';

import AuthService from '../services/auth.service';
import TokenService from '../services/token.service';
import UserService from '../services/user.service';

import { reload } from '../helpers/actionHelpers';

export const register = (dispatch, newUser) => AuthService.register(newUser)
    .then(
        () => {
            dispatch({ type: a.REGISTER_SUCCESS });
            dispatch({
                type: m.SET_MESSAGE,
                payload: 'auth.notifications.accountCreated',
            });
            reload(dispatch);
            return Promise.resolve();
        },
        (error) => {
            const message = (error.response
            && error.response.data
            && error.response.data.message)
          || error.message
          || error.toString();
            dispatch({
                type: a.REGISTER_FAIL,
            });
            dispatch({
                type: m.SET_MESSAGE,
                payload: message,
            });
            reload(dispatch);
            return Promise.reject(a.REGISTER_FAIL);
        },
    );

export const login = (dispatch, email, password) => AuthService.login(email, password).then(
    (response) => {
        if (response.data) {
            TokenService.setTokens(response.data.tokens);
            UserService.setUser(response.data.user);
        }
        dispatch({
            type: a.LOGIN_SUCCESS,
            payload: response,
        });
        dispatch({
            type: m.CANCEL_FORCE_CLOSE,
        });
        reload(dispatch);
        return Promise.resolve();
    },
    (error) => {
        const message = (error.response
            && error.response.data
            && error.response.data.message)
          || error.message
          || error.toString();
        dispatch({
            type: a.LOGIN_FAIL,
        });
        dispatch({
            type: m.SET_MESSAGE,
            payload: message,
            severity: 'error',
        });
        return Promise.reject();
    },
);

export const forgotPassword = (dispatch, email) => AuthService.forgotPassword(email).then(
    () => {
        dispatch({
            type: m.SET_MESSAGE,
            payload: 'auth.notifications.sent',
            severity: 'success',
        });
        reload(dispatch);
        return Promise.resolve();
    },
    (error) => {
        const message = (error.response
            && error.response.data
            && error.response.data.message)
          || error.message
          || error.toString();
        dispatch({
            type: a.LOGIN_FAIL,
        });
        dispatch({
            type: m.SET_MESSAGE,
            payload: message,
            severity: 'error',
        });
        reload(dispatch);
        return Promise.reject();
    },
);

export const resetPassword = (dispatch, password, token) => AuthService.resetPassword(password, token).then(
    () => {
        dispatch({
            type: m.SET_MESSAGE,
            payload: 'auth.notifications.reset',
            severity: 'success',
        });
        reload(dispatch);
        return Promise.resolve();
    },
    (error) => {
        const message = (error.response
            && error.response.data
            && error.response.data.message)
          || error.message
          || error.toString();
        dispatch({
            type: a.LOGIN_FAIL,
        });
        dispatch({
            type: m.SET_MESSAGE,
            payload: message,
            severity: 'error',
        });
        reload(dispatch);
        return Promise.reject();
    },
);

export const logout = (dispatch) => {
    AuthService.logout();
    dispatch({
        type: a.LOGOUT,
    });
    dispatch({
        type: m.CLEAR_MESSAGES,
    });
    reload(dispatch);
};
