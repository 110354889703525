// third-party
import { merge } from 'lodash';

// project import
import Badge from './Badge';
import Button from './Button';
import CardContent from './CardContent';
import Checkbox from './Checkbox';
import Chip from './Chip';
import IconButton from './IconButton';
import InputLabel from './InputLabel';
import LinearProgress from './LinearProgress';
import Link from './Link';
import ListItemIcon from './ListItemIcon';
import OutlinedInput from './OutlinedInput';
import Pagination from './Pagination';
import PaginationItem from './PaginationItem';
import Tab from './Tab';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFooter from './TableFooter';
import TableHead from './TableHead';
import TablePagination from './TablePagination';
import TableRow from './TableRow';
import Tabs from './Tabs';
import Typography from './Typography';
import MuiCssBaseline from './MuiCssBaseLine';
import Table from './Table';

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
    return merge(
        Button(theme),
        Badge(theme),
        CardContent(),
        Checkbox(theme),
        Chip(theme),
        IconButton(theme),
        InputLabel(theme),
        LinearProgress(),
        Link(theme),
        ListItemIcon(),
        Pagination(),
        PaginationItem(theme),
        OutlinedInput(theme),
        Tab(theme),
        TableBody(theme),
        TableCell(theme),
        TableFooter(theme),
        TableHead(theme),
        TablePagination(theme),
        TableRow(theme),
        Tabs(),
        Typography(),
        MuiCssBaseline(theme),
        Table(),
    );
}
