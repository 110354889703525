const envToBoolean = (value) => {
    if (value === 'false') {
        return false;
    }
    return !!value;
};

const envConfig = {
    basePathApi: process.env.REACT_APP_BASEPATH || 'build_placeholder_basepath_server_api',
    multiLanguage: envToBoolean(process.env.REACT_APP_MULTILANGUAGE || 'build_placeholder_use_multilanguage'),
    projectName: process.env.REACT_APP_PROJECT_NAME || 'build_placeholder_basepath_project_name',
    projectLogoUrl: process.env.REACT_APP_PROJECT_LOGO_URL || 'build_placeholder_basepath_project_logo_url',
    useDemoStatistics: process.env.REACT_APP_PROJECT_USE_DEMO_STATISTICS || 'build_placeholder_use_demo_statistics',
    heliosEnabled: process.env.REACT_APP_PROJECT_HELIOS_ENABLED || 'build_placeholder_use_helios_enabled',
    vatRate: process.env.REACT_APP_PROJECT_DEFAULT_VAT_RATE || 'build_placeholder_default_vat_rate',
};

export default envConfig;
